<template>
  <div v-show="show" class="fixed bottom-0 inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-10">
    <div v-show="show" @click="show = false" class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-400 opacity-75"></div>
    </div>
    <div class="relative flex flex-col bg-white border border-gray-300 rounded shadow z-20" style="width:600px;">
      <vue-loading :loading="loading" />
      <div class="flex">
        <div class="bg-blue-800 border-t-0 border-2 border-red-600 pl-1 pr-2 py-1 text-center rounded rounded-t-none flex items-center justify-start text-white font-bold text-xs">
          <img src="/img/loghi/micro.svg" class="w-4 mr-1" />
          <span class="font-normal text-gray-400">
            Mail informativa
          </span>
        </div>
        <div class="flex-1 flex flex-col">
          <div class="border-t-4 border-blue-800" style="margin-left:-2px; height:1px">&nbsp;</div>
          <div class="border-t-4 border-red-600" style="height:1px">&nbsp;</div>
        </div>
      </div>
      <div class="bg-white rounded max-h-full">
        <div class="m-2 p-2 relative">
          <div class="flex flex-col md:flex-row justify-between">
            <div class="w-full md:w-64 flex flex-col items-start">
              <label class="italic text-xs text-gray-600">destinatario email</label>
              <input v-model="form.email" type="text" class="w-full flex-auto border border-gray-400 rounded p-1 datepicker mask-date">
            </div>
          </div>
          <div class="flex justify-start mt-2">
            <div class="w-full flex flex-col items-start">
              <label class="italic text-xs text-gray-600">testo email</label>
              <div class="w-full border border-gray-400 rounded" style="min-height:450px">
                <!-- <editor api-key="ssg90s2tsgh6kn7m6arqo4shb6ituvppqws85kuiaklovq54" v-model="form.text" :init="{
                  statusbar: false,
                  menu: {
                    file: { title: '', items: '' },
                    view: { title: '', items: '' }
                  },
                  toolbar: true, // false
                  height: '500px'
                }" /> -->
                <ckeditor v-model="form.text" :editor="editor" :config="editorConfig"></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2 relative">
        <div class="flex justify-between pt-2 pb-2">
          <button @click="close" class="flex justify-between items-center px-2 bg-red-500 hover:bg-red-600 text-white text-sm font-semibold p-1 rounded">
            <fa class="s-times mr-2" />
            chiudi
          </button>
          <button @click="submit" class="flex justify-between items-center px-2 bg-green-500 hover:bg-green-600 text-white text-sm font-semibold p-1 rounded">
            <fa class="s-share mr-2" />
            invia
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'modal-info-mail-preview',
  data:() => ({
    show: false,
    loading: false,
    agency: {
      lastname: null,
      firstname: null,
      address: null,
      telephone: null,
      email: null
    },
    form: {
      email: null,
      text: null
    },
    editor: ClassicEditor,
    editorConfig: {

    }
  }),
  watch: {
    show(val) {
      this.setText()
    }
  },
  methods: {
    setText() {
      this.form.text = `
        <p style="font-size:14px; text-align:justify">
          Gentile Cliente,
          <br>
          la informiamo che l'acquisto dei biglietti è effettuabile dal sito <a style="color:#114d88;" href="https://alidaunia.it" target="_blank">alidaunia.it</a>.
          <br>
          <u>Solo per assistenza</u> è possibile contattare il call center al numero <span style="font-weight:bold">0881.619696</span>, attivo dal lunedì al venerdì dalle 15.30 alle 16.30.
          <br>
          Per i pagamenti si accettano carte di credito/debito Visa e MasterCard.
        </p>
        <p style="font-size:14px; text-align:justify">
          Il parcheggio è disponibile presso la sede Alidaunia, all'interno dell'area di sosta antistante il terminal, appositamente riservata ai passeggeri ed individuata dalle strisce di colore blu.
          <br>
          <span style="font-weight:bold; text-decoration:underline">Il parcheggio è tariffato 0,20 €/ora e la disponibilità di posti è limitata.</span>
        </p>
        <p style="font-size:14px; text-align:justify">
          Il check-in apre <span style="font-weight:bold; text-decoration:underline">45 minuti prima</span> dell'orario di partenza.
          <br>
          Il check-in ed il cancello d'ingresso chiudono tassativamente <span style="font-weight:bold; text-decoration:underline">20 minuti prima</span> dell'orario di partenza.
          <br>
          E' mandatorio presentarsi muniti del biglietto (anche in formato digitale pdf) e del documento d'identità dichiarato in fase di acquisto.
          <br>
          E' obbligatorio presentarsi al check-in con un <span style="font-weight:bold; text-decoration:underline">documento d'identità valido e in formato fisico</span>, pena imbarco negato. <span style="font-weight:bold; text-decoration:underline">Non saranno accettate foto o scansioni di esso.</span>
          <br>
          In caso di ritardo o mancata esibizione del titolo di viaggio o del documento di riconoscimento le sarà negato l'imbarco ed il biglietto andrà perso.
        </p>
        <p style="font-size:14px; text-align:justify">
          Per ulteriori eventuali informazioni e/o necessità è possibile consultare i seguenti link:
          <ul style="font-size:14px;">
            <li>
              <a style="color:#114d88;" href="https://bit.ly/elisuperficie-foggia" target="_blank">Geolocalizzazione dell'eliporto di Foggia</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://bit.ly/elisuperficie-vieste" target="_blank">Geolocalizzazione dell'eliporto di Vieste</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://bit.ly/elisuperficie-peschici" target="_blank">Geolocalizzazione dell'eliporto di Peschici</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://bit.ly/elisuperficie-tremiti-san-domino" target="_blank">Geolocalizzazione dell'eliporto di Tremiti</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/timetable" target="_blank">Orari</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/fares" target="_blank">Tariffe</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/pdf/docs/it/Carta_dei_servizi.pdf" target="_blank">Carta dei servizi</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/pdf/docs/it/Condizioni_generali_di_trasporto.pdf" target="_blank">Condizioni generali di trasporto</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/pdf/docs/it/Informativa_passeggeri.pdf" target="_blank">Informativa passeggeri</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/pdf/docs/it/Regolamento_trasporto_bagagli_animali_e_articoli.pdf" target="_blank">Bagagli, animali, articoli</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/pdf/docs/it/Regolamento_assistenze_speciali.pdf" target="_blank">Assistenze speciali</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/pdf/docs/it/Regolamento_parcheggio.pdf" target="_blank">Regolamento parcheggio</a>
            </li>
            <li>
              <a style="color:#114d88;" href="https://alidaunia.it/pdf/docs/it/Informativa_al_trattamento_dei_dati_personali.pdf" target="_blank">Informativa privacy</a>
            </li>
          </ul>
        </p>
        <p style="font-size:14px; text-align:justify">
          Qualora abbia necessità di ulteriori particolari, la invitiamo a visitare il sito <a style="color:#114d88;" href="https://alidaunia.it" target="_blank">alidaunia.it</a>.
        </p>
        <p style="font-size:14px;">
          <br>
          Cordiali saluti,
          <br><br>
          Assistenza telefonica / Customer care
          <br>
          Alidaunia Srl
          <br>
          S.S. 673 Km 19.00
          <br>
          71122 - FOGGIA
          <br>
          ITALY
          <br>
          <a style="color:#114d88;" href="mailto:prenotazioni@alidaunia.it" target="_blank">prenotazioni@alidaunia.it</a>
        </p>
        <br>
      </p>
      `
    },
    close() {
      this.show = false
    },
    async submit() {
      try {
        let { form } = this

        function validateEmail(email) {
          var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        }
        if (! validateEmail(form.email)) {
          notifyW('email non valida!')
          return
        }

        this.loading = true
        let payload = { ...form }
        let { data } = await api.post('api.send-info-mail', payload)
        notifyS('Email inviata!')
        this.loading = false
        this.show = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    }
  }
}
</script>
